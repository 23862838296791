import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca provisioner add`}</strong>{` -- add a provisioner`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca provisioner add <name> --type=JWK [--public-key=<file>]
[--private-key=<file>] [--create] [--password-file=<file>]
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

ACME

step ca provisioner add <name> --type=ACME
[--force-cn] [--require-eab] [--challenge=<challenge>]
[--attestation-format=<format>] [--attestation-roots=<file>]
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

OIDC

step ca provisioner add <name> --type=OIDC
[--client-id=<id>] [--client-secret=<secret>]
[--configuration-endpoint=<url>] [--domain=<domain>]
[--admin=<email>]...
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

X5C

step ca provisioner add <name> --type=X5C --x5c-roots=<file>
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

SSHPOP

step ca provisioner add <name> --type=SSHPOP
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

Nebula

step ca provisioner add <name> --type=Nebula --nebula-root=<file>
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

K8SSA

step ca provisioner add <name> --type=K8SSA [--public-key=<file>]
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

IID

step ca provisioner add <name> --type=[AWS|Azure|GCP]
[--aws-account=<id>] [--gcp-service-account=<name>] [--gcp-project=<name>]
[--azure-tenant=<id>] [--azure-resource-group=<name>]
[--azure-audience=<name>] [--azure-subscription-id=<id>]
[--azure-object-id=<id>] [--instance-age=<duration>] [--iid-roots=<file>]
[--disable-custom-sans] [--disable-trust-on-first-use]
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]

SCEP

step ca provisioner add <name> --type=SCEP [--force-cn] [--challenge=<challenge>]
[--capabilities=<capabilities>] [--include-root] [--min-public-key-length=<length>]
[--encryption-algorithm-identifier=<id>]
[--admin-cert=<file>] [--admin-key=<file>]
[--admin-subject=<subject>] [--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>] [--ca-config=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca provisioner add`}</strong>{` adds a provisioner to the CA configuration.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`name`}</inlineCode>{`
The name of the provisioner.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--type`}</strong>{`=`}<inlineCode parentName="p">{`type`}</inlineCode>{`
The `}<inlineCode parentName="p">{`type`}</inlineCode>{` of provisioner to create.`}</p>
    <p><inlineCode parentName="p">{`type`}</inlineCode>{` is a case-insensitive string and must be one of:`}</p>
    <p><strong parentName="p">{`JWK`}</strong>{`
Uses an JWK key pair to sign provisioning tokens. (default)`}</p>
    <p><strong parentName="p">{`OIDC`}</strong>{`
Uses an OpenID Connect provider to sign provisioning tokens.`}</p>
    <p><strong parentName="p">{`AWS`}</strong>{`
Uses Amazon AWS instance identity documents.`}</p>
    <p><strong parentName="p">{`GCP`}</strong>{`
Use Google instance identity tokens.`}</p>
    <p><strong parentName="p">{`Azure`}</strong>{`
Uses Microsoft Azure identity tokens.`}</p>
    <p><strong parentName="p">{`ACME`}</strong>{`
Uses the ACME protocol to create certificates.`}</p>
    <p><strong parentName="p">{`X5C`}</strong>{`
Uses an X509 certificate / private key pair to sign provisioning tokens.`}</p>
    <p><strong parentName="p">{`K8SSA`}</strong>{`
Uses Kubernetes Service Account tokens.`}</p>
    <p><strong parentName="p">{`SSHPOP`}</strong>{`
Uses an SSH certificate / private key pair to sign provisioning tokens.`}</p>
    <p><strong parentName="p">{`SCEP`}</strong>{`
Uses the SCEP protocol to create certificates.`}</p>
    <p><strong parentName="p">{`Nebula`}</strong>{`
Uses a Nebula certificate / private key pair to sign provisioning tokens.`}</p>
    <p><strong parentName="p">{`--public-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the JWK public key. Or, a `}<inlineCode parentName="p">{`file`}</inlineCode>{`
containing one or more PEM formatted keys, if used with the K8SSA provisioner.`}</p>
    <p><strong parentName="p">{`--create`}</strong>{`
Create the JWK key pair for the provisioner.`}</p>
    <p><strong parentName="p">{`--private-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the JWK private key.`}</p>
    <p><strong parentName="p">{`--client-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the audience in an OpenID Connect token.`}</p>
    <p><strong parentName="p">{`--client-secret`}</strong>{`=`}<inlineCode parentName="p">{`secret`}</inlineCode>{`
The `}<inlineCode parentName="p">{`secret`}</inlineCode>{` used to obtain the OpenID Connect tokens.`}</p>
    <p><strong parentName="p">{`--listen-address`}</strong>{`=`}<inlineCode parentName="p">{`address`}</inlineCode>{`
The callback `}<inlineCode parentName="p">{`address`}</inlineCode>{` used in the OpenID Connect flow (e.g. ":10000")`}</p>
    <p><strong parentName="p">{`--configuration-endpoint`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
OpenID Connect configuration `}<inlineCode parentName="p">{`url`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`--admin`}</strong>{`=`}<inlineCode parentName="p">{`email`}</inlineCode>{`
The `}<inlineCode parentName="p">{`email`}</inlineCode>{` of an admin user in an OpenID Connect provisioner, this user
will not have restrictions in the certificates to sign. Use the
'--admin' flag multiple times to configure multiple administrators.`}</p>
    <p><strong parentName="p">{`--domain`}</strong>{`=`}<inlineCode parentName="p">{`domain`}</inlineCode>{`
The `}<inlineCode parentName="p">{`domain`}</inlineCode>{` used to validate the email claim in an OpenID Connect provisioner.
Use the '--domain' flag multiple times to configure multiple domains.`}</p>
    <p><strong parentName="p">{`--group`}</strong>{`=`}<inlineCode parentName="p">{`group`}</inlineCode>{`
The `}<inlineCode parentName="p">{`group`}</inlineCode>{` list used to validate the groups extension in an OpenID Connect token.
Use the '--group' flag multiple times to configure multiple groups.`}</p>
    <p><strong parentName="p">{`--tenant-id`}</strong>{`=`}<inlineCode parentName="p">{`tenant-id`}</inlineCode>{`
The `}<inlineCode parentName="p">{`tenant-id`}</inlineCode>{` used to replace the templatized {tenantid} in the OpenID Configuration.`}</p>
    <p><strong parentName="p">{`--x5c-roots`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--x5c-root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
PEM-formatted root certificate(s) `}<inlineCode parentName="p">{`file`}</inlineCode>{` used to validate the signature on X5C
provisioning tokens.`}</p>
    <p><strong parentName="p">{`--nebula-root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Root certificate (chain) `}<inlineCode parentName="p">{`file`}</inlineCode>{` used to validate the signature on Nebula
provisioning tokens.`}</p>
    <p><strong parentName="p">{`--require-eab`}</strong>{`
Require (and enable) External Account Binding (EAB) for Account creation.
If this flag is set to false, then disable EAB.`}</p>
    <p><strong parentName="p">{`--force-cn`}</strong>{`
Always set the common name in provisioned certificates.`}</p>
    <p><strong parentName="p">{`--challenge`}</strong>{`=`}<inlineCode parentName="p">{`challenge`}</inlineCode>{`
With a SCEP provisioner the `}<inlineCode parentName="p">{`challenge`}</inlineCode>{` is a shared secret between a
client and the CA.`}</p>
    <p>{`With an ACME provisioner, this flag specifies the `}<inlineCode parentName="p">{`challenge`}</inlineCode>{` or challenges to
enable. Use the flag multiple times to configure multiple challenges.`}</p>
    <p>{`The supported ACME challenges are:`}</p>
    <p><strong parentName="p">{`http-01`}</strong>{`
With the HTTP challenge, the client in an ACME transaction proves its control
over a domain name by proving that it can provision HTTP resources on a server
accessible under that domain name.`}</p>
    <p><strong parentName="p">{`dns-01`}</strong>{`
With the DNS challenge, the client can prove control of a domain by
provisioning a TXT resource record containing a designated value for a specific
validation domain name.`}</p>
    <p><strong parentName="p">{`tls-alpn-01`}</strong>{`
With the TLS with Application-Layer Protocol Negotiation (TLS ALPN) challenge,
the client can prove control over a domain name by configuring a TLS server to
respond to specific connection attempts using the ALPN extension with
identifying information.`}</p>
    <p><strong parentName="p">{`device-attest-01`}</strong>{`
With the device attestation challenge, the client can prove control over a
permanent identifier of a device by providing an attestation statement
containing the identifier of the device.`}</p>
    <p>{`If the provisioner has no challenges configured, http-01, dns-01 and tls-alpn-01
will be automatically enabled.`}</p>
    <p><strong parentName="p">{`--attestation-format`}</strong>{`=`}<inlineCode parentName="p">{`format`}</inlineCode>{`
Enable an ACME attestation statement `}<inlineCode parentName="p">{`format`}</inlineCode>{` in the provisioner. Use the flag
multiple times to configure multiple challenges.`}</p>
    <p>{`The supported ACME attestation formats are:`}</p>
    <p><strong parentName="p">{`apple`}</strong>{`
With the apple format, Apple devices can use the device-attest-01 challenge to
get a new certificate.`}</p>
    <p><strong parentName="p">{`step`}</strong>{`
With the step format, devices like YubiKeys that can generate an attestation
certificate can use the device-attest-01 challenge to get a new certificate.`}</p>
    <p><strong parentName="p">{`tpm`}</strong>{`
With the tpm format, devices with TPMs can use the device-attest-01 challenge
to get a new certificate.`}</p>
    <p><strong parentName="p">{`--attestation-roots`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
PEM-formatted root certificate(s) `}<inlineCode parentName="p">{`file`}</inlineCode>{` used to validate the attestation
certificates. Use the flag multiple times to read from multiple files.`}</p>
    <p><strong parentName="p">{`--capabilities`}</strong>{`=`}<inlineCode parentName="p">{`capabilities`}</inlineCode>{`
The SCEP `}<inlineCode parentName="p">{`capabilities`}</inlineCode>{` to advertise`}</p>
    <p><strong parentName="p">{`--include-root`}</strong>{`
Include the CA root certificate in the SCEP CA certificate chain`}</p>
    <p><strong parentName="p">{`--min-public-key-length`}</strong>{`=`}<inlineCode parentName="p">{`length`}</inlineCode>{`
The minimum public key `}<inlineCode parentName="p">{`length`}</inlineCode>{` of the SCEP RSA encryption key`}</p>
    <p><strong parentName="p">{`--encryption-algorithm-identifier`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The `}<inlineCode parentName="p">{`id`}</inlineCode>{` for the SCEP encryption algorithm to use.
Valid values are 0 - 4, inclusive. The values correspond to:
0: DES-CBC,
1: AES-128-CBC,
2: AES-256-CBC,
3: AES-128-GCM,
4: AES-256-GCM.
Defaults to DES-CBC (0) for legacy clients.`}</p>
    <p><strong parentName="p">{`--aws-account`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The AWS account `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity documents.
Use the flag multiple times to configure multiple accounts.`}</p>
    <p><strong parentName="p">{`--azure-tenant`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Microsoft Azure tenant `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.`}</p>
    <p><strong parentName="p">{`--azure-resource-group`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The Microsoft Azure resource group `}<inlineCode parentName="p">{`name`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple resource groups`}</p>
    <p><strong parentName="p">{`--azure-audience`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The Microsoft Azure audience `}<inlineCode parentName="p">{`name`}</inlineCode>{` used to validate the identity tokens.`}</p>
    <p><strong parentName="p">{`--azure-subscription-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Microsoft Azure subscription `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple subscription IDs`}</p>
    <p><strong parentName="p">{`--azure-object-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Microsoft Azure AD object `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple object IDs`}</p>
    <p><strong parentName="p">{`--gcp-service-account`}</strong>{`=`}<inlineCode parentName="p">{`email`}</inlineCode>{`
The Google service account `}<inlineCode parentName="p">{`email`}</inlineCode>{` or `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple service accounts.`}</p>
    <p><strong parentName="p">{`--gcp-project`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Google project `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple projects`}</p>
    <p><strong parentName="p">{`--instance-age`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` to grant a certificate in AWS and GCP provisioners.
A `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is sequence of decimal numbers, each with optional fraction and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--disable-custom-sans`}</strong>{`
On cloud provisioners, if enabled only the internal DNS and IP will be added as a SAN.
By default it will accept any SAN in the CSR.`}</p>
    <p><strong parentName="p">{`--disable-trust-on-first-use`}</strong>{`, `}<strong parentName="p">{`--disable-tofu`}</strong>{`
On cloud provisioners, if enabled multiple sign request for this provisioner
with the same instance will be accepted. By default only the first request
will be accepted.`}</p>
    <p><strong parentName="p">{`--x509-template`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The x509 certificate template `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON representation of the certificate to create.`}</p>
    <p><strong parentName="p">{`--x509-template-data`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The x509 certificate template data `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON map of data that can be used by the certificate template.`}</p>
    <p><strong parentName="p">{`--ssh-template`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The x509 certificate template `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON representation of the certificate to create.`}</p>
    <p><strong parentName="p">{`--ssh-template-data`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The ssh certificate template data `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON map of data that can be used by the certificate template.`}</p>
    <p><strong parentName="p">{`--x509-min-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The minimum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an x509 certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--x509-max-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an x509 certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--x509-default-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The default `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an x509 certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--ssh-user-min-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The minimum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh user certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--ssh-user-max-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh user certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--ssh-user-default-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh user certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--ssh-host-min-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The minimum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh host certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--ssh-host-max-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh host certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--ssh-host-default-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh host certificate generated by this provisioner.
Value must be a sequence of decimal numbers, each with optional fraction, and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--disable-renewal`}</strong>{`
Disable renewal for all certificates generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--allow-renewal-after-expiry`}</strong>{`
Allow renewals for expired certificates generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--ssh`}</strong>{`
Enable provisioning of ssh certificates. The default value is true. To
disable ssh use '--ssh=false'.`}</p>
    <p><strong parentName="p">{`--admin-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Admin certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--admin-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the admin certificate that will
be stored in the 'x5c' header.`}</p>
    <p><strong parentName="p">{`--admin-subject`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`, `}<strong parentName="p">{`--admin-name`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`
The admin `}<inlineCode parentName="p">{`subject`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--admin-provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--admin-issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--admin-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Create a JWK provisioner with newly generated keys and a template for x509 certificates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add cicd --type JWK --create --x509-template ./templates/example.tpl
`}</code></pre>
    <p>{`Create a JWK provisioner and explicitly select the configuration file to update:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add cicd --type JWK --create --ca-config /path/to/ca.json
`}</code></pre>
    <p>{`Create a JWK provisioner with duration claims:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add cicd --type JWK --create --x509-min-dur 20m --x509-default-dur 48h --ssh-user-min-dur 17m --ssh-host-default-dur 16h
`}</code></pre>
    <p>{`Create a JWK provisioner with existing keys:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add jane@doe.com --type JWK --public-key jwk.pub --private-key jwk.priv
`}</code></pre>
    <p>{`Create an OIDC provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add Google --type OIDC --ssh \\
  --client-id 1087160488420-8qt7bavg3qesdhs6it824mhnfgcfe8il.apps.googleusercontent.com \\
  --client-secret udTrOT3gzrO7W9fDPgZQLfYJ \\
  --configuration-endpoint https://accounts.google.com/.well-known/openid-configuration
`}</code></pre>
    <p>{`Create an X5C provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add x5c --type X5C --x5c-roots x5c_ca.crt
`}</code></pre>
    <p>{`Create an ACME provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add acme --type ACME
`}</code></pre>
    <p>{`Create an ACME provisioner, forcing a CN and requiring EAB:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add acme --type ACME --force-cn --require-eab
`}</code></pre>
    <p>{`Create an ACME provisioner for device attestation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add attestation --type ACME --challenge device-attest-01
`}</code></pre>
    <p>{`Create an K8SSA provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add kube --type K8SSA --ssh --public-key key.pub
`}</code></pre>
    <p>{`Create an SSHPOP provisioner for renewing SSH host certificates:")`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add sshpop --type SSHPOP
`}</code></pre>
    <p>{`Create a SCEP provisioner with 'secret' challenge and AES-256-CBC encryption:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add my_scep_provisioner --type SCEP --challenge secret --encryption-algorithm-identifier 2
`}</code></pre>
    <p>{`Create an Azure provisioner with two resource groups, one subscription ID and one object ID:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca provisioner add Azure --type Azure \\
  --azure-tenant bc9043e2-b645-4c1c-a87a-78f8644bfe57 \\
  --azure-resource-group identity --azure-resource-group accounting \\
  --azure-subscription-id dc760a01-2886-4a84-9abc-f3508e0f87d9 \\
  --azure-object-id f50926c7-abbf-4c28-87dc-9adc7eaf3ba7
`}</code></pre>
    <p>{`Create an GCP provisioner that will only accept the SANs provided in the identity token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca provisioner add Google --type GCP \\
  --disable-custom-sans --gcp-project internal
`}</code></pre>
    <p>{`Create an AWS provisioner that will only accept the SANs provided in the identity
document and will allow multiple certificates from the same instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca provisioner add Amazon --type AWS \\
  --aws-account 123456789 --disable-custom-sans --disable-trust-on-first-use
`}</code></pre>
    <p>{`Create an AWS provisioner that will use a custom certificate to validate the instance
identity documents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca provisioner add Amazon --type AWS \\
  --aws-account 123456789
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      